
 .card {
 
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
} 
/* Card hover animation */
.animated-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.animated-card:hover {
  transform: translateY(-10px) scale(1.03);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3);
}

/* Button hover animation */
.btnprimary {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btnprimary:hover {
  background-color: #007bff;
  color: #ffffff;
}

/* Smooth card appearance animation */
.animated-card {
  opacity: 0;
  animation: fadeInUp 0.5s ease forwards;
}
/* Default card styling */
.hover-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Hover effect */
.hover-card:hover {
  transform: scale(1.05); /* Slightly increase size */
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

/* Keyframes for fade-in effect */
@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

