:root {
  /* --primary-green2: #4fbb26; */
  --primary-green2: var(--primary-green2);
  /* --primary-gradient:var(--primary-gradient) */
}


@media (min-width: 992px) {
  .has-apps-sidebar .nk-sidebar {
    left: 0px !important;
  }
  .modal-xl {
    --bs-modal-width: 1200px !important;
  }
}
.nk-content{
  margin: 0 !important;
  padding: 0 !important;
}
/* .nk-content{
    margin-top: 60px;
} */

.nk-menu-link {
  font-weight: 400 !important;
  font-size: 16px !important;
}

.nk-menu-md .nk-menu-icon .icon {
  font-size: 1.4rem !important;
}

.nk-menu-icon {
  line-height: 2 !important;
}

.nk-header-tools {
  margin-left: auto;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.nk-menu-item:hover {
  background-color: var(--primary-green2);
  border-radius: 4px;
}

.dark-mode .nk-menu-item.active{
  background-color: #eeffeb19;
}

.dark-mode .nk-menu-item:hover{
  background-color: #eeffeb19;
}

.dark-mode .shimmer{
  background: linear-gradient(to right, #f6f6f641 8%, #f6f6f64f 18%, #f6f6f641 33%);
}

.dark-mode .cTsUPQ{
  background-color: #eeffeb19;
}

.nk-menu-item {
  display: flex;
  align-items: center;
  padding: 0px 12px !important;
  margin: 4px 00px !important;
  cursor: pointer;
}

.nk-menu-item.active {
  background-color: var(--primary-green2);
  border-radius: 4px;
}

.nk-quick-nav-icon {
  width: 35px !important;
  height: 35px !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.btnprimary {
  background: linear-gradient(23deg, #000, #216306 35%, #45e742) !important;
  border-color: #4fbb26 !important;
  border-radius: 2px !important;
}

.nk-notification-item:hover {
  background-color: var(--primary-green2)!important;
}

.nk-sidebar.is-light {
  border-right-color: #c8d7e8 !important;
}

.nk-wrap.bg-light {
  background-color: white !important;
}

.sub-text {
  font-weight: 500 !important;
  font-size: 11px !important;
}

.nk-tb-list .tb-sub,
.nk-tb-list .tb-amount-sm {
  font-size: 1.2em !important;
}

.nk-tb-head .nk-tb-col {
  font-size: 1.3em !important;
  font-weight: 500 !important;
}

.nk-tb-list .tb-lead,
.nk-tb-list .tb-amount {
  font-weight: normal !important;
}

.text-body {
  font-size: 16px !important;
}
.view-text {
  cursor: pointer; 
}

.view-text:hover{
  text-decoration: underline; 
  color: #007bff; 
  font-weight: bold; 
}

.tableHover :hover {
  background-color: #f3f6f0;
  cursor: pointer;
 
}
.Reactdatatable{
  margin-top: -4%;
}
.showdiv{
  display: flex;
  justify-content: end;
}
.custom-class{
  width: 75% !important;
}
@media (max-width: 667px) {
  .Reactdatatable {
   /* margin-top: -15%;  Removes the negative margin */
    justify-content: flex-start; /* Ensures alignment starts at the beginning */
  }
  .showdiv{
    display: flex;
    justify-content: start;
  }
}


.nk-tb-col.hoverable:hover {
  background-color: #f8f9fa; /* Light gray */
  cursor: pointer; /* Show pointer on hover */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}
.captch{
  width: 100% !important;
}

@media (max-width: 768px) {
  .invoice-button{
    font-size: 0.9rem; 
    padding: 6px 10px; 
  }
}
.borderColor{
  border: 1px solid rgba(99, 170, 29, 0.5);
}
@media (max-width: 576px) {
  .custom-class {
    width: 100% !important;
  }
}


