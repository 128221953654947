/* styles.css */
:root {
  /* --primary-green2: #4fbb26; */
  --primary-green2: var(--primary-green2);
  /* --primary-gradient:var(--primary-gradient) */
}

body {
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: your-colors-here;
}

/* WebKit (Chrome, Safari) */
*::-webkit-scrollbar {
  width: thin;
}

.stripebutton {
  /* / background: #60cf4f; / */
  background: linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%) !important;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  /* / display: block; / */
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 10%;
}

.stripebutton:hover {
  filter: contrast(115%);
  background: linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%) !important;
}

.stripebutton:disabled {
  opacity: 0.5;
  cursor: default;
}

.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #60cf4f;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #60cf4f;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@media only screen and (min-width: 150px) {
  .paypal-button-row {
      min-height: 32px!important;
      padding: 0px 10px!important;
  }
}
 
